import React from 'react'
import Helmet from 'react-helmet';
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = (props) => {
  return (
    <Layout>
      <SEO title="top" />
      <Helmet>
        <title>blog</title>
      </Helmet>
    </Layout>
  )
}

export default IndexPage
